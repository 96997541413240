.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
	background-color: get-color(primary, 1);
	background: linear-gradient(65deg, get-color(primary, 1) 0, #1E4D92 100%);
}

@include media( '>medium' ) {

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
