@include media( '>medium' ) {

	.has-animations {

        .timeline-item {

            &:nth-child(even) {

                .timeline-item-content {

                    &.reveal-from-side {
                        transform: translateX($scroll-reveal--from-left-amount);
                    }
                }  
            }

            &:nth-child(odd) {

                .timeline-item-content {

                    &.reveal-from-side {
                        transform: translateX($scroll-reveal--from-right-amount);
                    }
                }  
            }            
        }

        &.is-loaded {

            .timeline-item {


                .timeline-item-content {

                    &.is-revealed {
                        transform: translate(0);
                    }
                }
            }
        }
    }
}